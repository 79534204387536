(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/better-dialog/assets/javascripts/better-dialog.js') >= 0) return;  svs.modules.push('/components/lb-ui/better-dialog/assets/javascripts/better-dialog.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _classPrivateFieldSet(s, a, r) { return s.set(_assertClassBrand(s, a), r), r; }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }
const {
  dialog,
  dialog: {
    api: dialogApi
  }
} = svs.ui;
const dialogTypes = {
  Confirm: 'Confirm'
};
const getDefaultDialogAction = () => ({
  title: 'Okej',
  callback(dialog) {
    dialog.close();
  }
});
var _dialogInstance = new WeakMap();
class ActualDialog {
  constructor(dialogInstance) {
    _classPrivateFieldInitSpec(this, _dialogInstance, void 0);
    _classPrivateFieldSet(_dialogInstance, this, dialogInstance);
  }
  open() {
    dialogApi.add(_classPrivateFieldGet(_dialogInstance, this));
    return this;
  }
  close() {
    _classPrivateFieldGet(_dialogInstance, this).close();
    return this;
  }
}
var _classType = new WeakMap();
var _type = new WeakMap();
var _branding = new WeakMap();
var _textLines = new WeakMap();
var _title = new WeakMap();
var _icon = new WeakMap();
var _actions = new WeakMap();
class BetterDialog {
  constructor() {
    _classPrivateFieldInitSpec(this, _classType, dialogTypes.Confirm);
    _classPrivateFieldInitSpec(this, _type, dialog.type.CONFIRM);
    _classPrivateFieldInitSpec(this, _branding, dialog.branding.SPORT);
    _classPrivateFieldInitSpec(this, _textLines, []);
    _classPrivateFieldInitSpec(this, _title, '');
    _classPrivateFieldInitSpec(this, _icon, undefined);
    _classPrivateFieldInitSpec(this, _actions, []);
  }
  setClassType(type) {
    if (!BetterDialog.CLASS_TYPES[type]) {
      throw new Error("Unsupported type: ".concat(type));
    }
    _classPrivateFieldSet(_classType, this, type);
    return this;
  }
  setType(type) {
    if (!BetterDialog.TYPES[type]) {
      throw new Error("Unsupported type: ".concat(type));
    }
    _classPrivateFieldSet(_type, this, type);
    return this;
  }
  setTitle(title) {
    _classPrivateFieldSet(_title, this, title);
    return this;
  }
  setIcon(icon) {
    _classPrivateFieldSet(_icon, this, icon);
    return this;
  }
  addText(text) {
    let type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : BetterDialog.TEXT_TYPES.TEXT;
    if (!BetterDialog.TEXT_TYPES[type]) {
      throw new Error("Unsupported type: ".concat(type));
    }
    _classPrivateFieldGet(_textLines, this).push({
      type,
      text
    });
    return this;
  }
  addAction(title, callback) {
    let showLoader = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    const _callback = (a, b, dialog) => callback(dialog);
    _classPrivateFieldGet(_actions, this).push({
      title,
      callback: _callback,
      showLoader
    });
    return this;
  }
  compose() {
    if (!_classPrivateFieldGet(_actions, this).length) {
      const {
        title,
        callback
      } = getDefaultDialogAction();
      this.addAction(title, callback);
    }
    return new ActualDialog(new dialog[_classPrivateFieldGet(_classType, this)]({
      type: _classPrivateFieldGet(_type, this),
      title: _classPrivateFieldGet(_title, this),
      message: _classPrivateFieldGet(_textLines, this).length ? _classPrivateFieldGet(_textLines, this) : undefined,
      actions: _classPrivateFieldGet(_actions, this),
      branding: _classPrivateFieldGet(_branding, this),
      icon: _classPrivateFieldGet(_icon, this)
    }));
  }
}
_defineProperty(BetterDialog, "CLASS_TYPES", dialogTypes);
_defineProperty(BetterDialog, "TEXT_TYPES", {
  TEXT: 'TEXT'
});
_defineProperty(BetterDialog, "TYPES", Object.keys(dialog.type).reduce((acc, v) => _objectSpread(_objectSpread({}, acc), {}, {
  [v]: v
}), {}));
setGlobal('svs.components.lbUi.betterDialog', {
  BetterDialog,
  ActualDialog
});

 })(window);