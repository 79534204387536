(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/better-dialog/assets/javascripts/use-dialog.js') >= 0) return;  svs.modules.push('/components/lb-ui/better-dialog/assets/javascripts/use-dialog.js');
"use strict";

const {
  BetterDialog,
  ActualDialog
} = svs.components.lbUi.betterDialog;
const {
  useCallback,
  useRef,
  useMemo
} = React;
const createDialog = (_ref, resolve) => {
  let {
    title,
    declineText,
    acceptText,
    messages,
    showLoader,
    icon,
    autoCloseOnAccept
  } = _ref;
  const dialog = new BetterDialog().setTitle(title);
  messages.forEach(message => {
    dialog.addText(message);
  });
  if (icon) {
    dialog.setIcon(icon);
  }
  if (declineText) {
    dialog.addAction(declineText, dialog => {
      dialog.close();
      resolve(false);
    });
  }
  dialog.addAction(acceptText, dialog => {
    autoCloseOnAccept && dialog.close();
    resolve(true);
  }, showLoader);
  return dialog.compose();
};
const useDialog = function () {
  let {
    title,
    declineText,
    acceptText = 'Fortsätt',
    messages = [],
    showLoader,
    icon,
    autoCloseOnAccept = false
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (!acceptText) {
    throw new Error('Accept text must be provided');
  }
  if (!title) {
    throw new Error('Title must be provided');
  }
  const dialog = useRef();
  const isOpen = useRef(false);
  const promiseRef = useRef();
  const open = useCallback(() => {
    let resolve;
    if (isOpen.current) {
      return promiseRef.current;
    }
    isOpen.current = true;
    promiseRef.current = new Promise(_resolve => {
      resolve = _resolve;
    });
    dialog.current = createDialog({
      title,
      declineText,
      acceptText,
      messages,
      showLoader,
      icon,
      autoCloseOnAccept
    }, resolve).open();
    return promiseRef.current;
  }, [title, declineText, acceptText, messages, showLoader, icon, autoCloseOnAccept]);
  const close = useCallback(() => {
    if (dialog.current instanceof ActualDialog) {
      isOpen.current = false;
      dialog.current.close();
    }
  }, []);
  return useMemo(() => ({
    open,
    close
  }), [open, close]);
};
setGlobal('svs.components.lbUi.betterDialog.useDialog', useDialog);

 })(window);